import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";
import { graphql } from "gatsby";

export default function LostInJapan({ data }) {
  return (
    <Layout>
      <Helmet title="Lost in Japan | Shawn Mendes" />

      <h1>Lost in Japan</h1>

      <h2>Shawn Mendes</h2>

      <h4>Key: Am</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Img fluid={data.file.childImageSharp.fluid} />

      <Columns columns="2">
        <Verse>
          <p>All it'd take is one flight</p>
          <p>We'd be in the same time zone</p>
          <p>Looking through your timeline</p>
          <p>Seeing all the rainbows, I</p>
          <p>I got an idea</p>
          <p>And I know that it sounds crazy</p>
          <p>I just wanna see ya</p>
          <p>Oh, I gotta ask</p>
        </Verse>
        <Chorus>
          <p>Do you got plans tonight?</p>
          <p>I'm a couple hundred miles from Japan, and I</p>
          <p>I was thinking I could fly to your hotel tonight</p>
          <p>'Cause I-I-I can't get you off my mind</p>
          <p>Can't get you off my mind</p>
          <p>Can't get you off my mind (oh)</p>
        </Chorus>
        <Verse>
          <p>I could feel the tension</p>
          <p>We could cut it with a knife</p>
          <p>I know it's more than just a friendship</p>
          <p>I can hear you think I'm right, yeah</p>
          <p>Do I gotta convince you</p>
          <p>That you shouldn't fall asleep?</p>
          <p>It'll only be a couple hours</p>
          <p>And I'm about to leave</p>
        </Verse>
        <Chorus>
          <p>Do you got plans tonight?</p>
          <p>I'm a couple hundred miles from Japan, and I</p>
          <p>I was thinking I could fly to your hotel tonight</p>
          <p>'Cause I-I-I can't get you off my mind</p>
          <p>Can't get you off my mind</p>
          <p>Can't get you off my mind (oh)</p>
          <p>Do you got plans tonight?</p>
          <p>I was hoping I could get lost in your paradise</p>
          <p>The only thing I'm thinking 'bout is you and I</p>
          <p>'Cause I-I-I can't get you off my mind</p>
          <p>Can't get you off my mind</p>
          <p>I can't seem to get you off my mind</p>
        </Chorus>
        <Bridge>
          <p>Let's get lost tonight (Oh)</p>
          <p>Let's get lost tonight</p>
          <p>Baby, you and</p>
          <p>I can't seem to get you off my mind</p>
          <p>Let's get lost tonight (Oh)</p>
          <p>Let's get lost tonight</p>
          <p>Baby, you and</p>
          <p>I can't seem to get you off my mind</p>
        </Bridge>
        <Chorus>
          <p>Do you got plans tonight?</p>
          <p>I'm a couple hundred miles from Japan, and I</p>
          <p>I was thinking I could fly to your hotel tonight</p>
          <p>'Cause I-I-I can't get you off my mind</p>
          <p>Can't get you off my mind</p>
          <p>Can't get you off my mind (oh)</p>
          <p>Do you got plans tonight?</p>
          <p>I was hoping I could get lost in your paradise</p>
          <p>The only thing I'm thinking 'bout is you and I</p>
          <p>'Cause I-I-I can't get you off my mind</p>
          <p>Can't get you off my mind</p>
          <p>I can't seem to get you off my mind</p>
        </Chorus>
        <Bridge>
          <p>Let's get lost tonight (Oh)</p>
          <p>Let's get lost tonight</p>
          <p>Baby, you and</p>
          <p>I can't seem to get you off my mind</p>
          <p>Let's get lost tonight (Oh)</p>
          <p>Let's get lost tonight</p>
          <p>Baby, you and</p>
          <p>I can't seem to get you off my mind</p>
        </Bridge>
      </Columns>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "lost-in-japan.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
